import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid.css';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ViewportRowModelModule } from '@ag-grid-enterprise/viewport-row-model';

LicenseManager.setLicenseKey(
    'Using_this_AG_Grid_Enterprise_key_( AG-049105 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Alpha Trading Systems )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Alpha )_only_for_( 5 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Alpha )_need_to_be_licensed___( Alpha )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 12 December 2024 )____[v2]_MTczMzk2MTYwMDAwMA==87486400005ec11b638b3e4186e475ab',
);

ModuleRegistry.registerModules([
    // the modules to register
    ClientSideRowModelModule,
    ViewportRowModelModule,
    MenuModule,
    ColumnsToolPanelModule, // TODO: remove after we eliminate the columns tool panel
]);
