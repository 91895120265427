import { Duration, Instant } from '@js-joda/core';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { QueryClient, type QueryClientConfig } from '@tanstack/query-core';
import { persistQueryClientRestore, type PersistQueryClientProviderProps } from '@tanstack/react-query-persist-client';
import { injectable, inject } from 'src/features/ioc';
import type { ReactBindings } from 'src/ioc/types';
import { localforage } from 'src/lib/serialization/localForage';

const persistByDefault = false; // change to true if we want to do it by default in the future

export type ThinkAlphaQueryClient = QueryClient & {
    getUserQueryData: QueryClient['getQueryData'];
    setUserQueryData: QueryClient['setQueryData'];
    fetchUserQuery: QueryClient['fetchQuery'];
    readonly isRestoring: boolean;
    readonly isRestored: boolean;
    readonly restoring: Promise<void>;
};

export const QueryClientOptions: QueryClientConfig = {
    defaultOptions: {
        queries: {
            refetchOnReconnect: 'always',
            staleTime: Duration.ofDays(1).toMillis(),
            meta: { persist: persistByDefault },
        },
    },
};

export function makeUserQueryKey(userId: string | undefined, isSuperuserEnabled: boolean) {
    const userQueryKey = userId
        ? {
              id: userId,
              isSuperuserEnabled,
          }
        : {
              id: null,
              isSuperuserEnabled: false,
          };
    return userQueryKey;
}

@injectable()
export class ThinkAlphaQueryClientImpl extends QueryClient implements ThinkAlphaQueryClient {
    readonly restoring: Promise<void>;
    #isRestored = false;
    #isRestoring = true;

    get isRestored() {
        return this.#isRestored;
    }

    get isRestoring() {
        return this.#isRestoring;
    }

    constructor(@inject('Store') private store: ReactBindings['Store']) {
        super(QueryClientOptions);
        this.restoring = persistQueryClientRestore({ queryClient: this, ...persistOptions })
            .then(() => {
                this.#isRestored = true;
            })
            .finally(() => {
                this.#isRestoring = false;
            });
    }

    getUserQueryData: QueryClient['getQueryData'] = (key, ...params) => {
        const user = this.store.getState().auth.user;
        const superuserStatus = this.store.getState().ui.superuserAccessEnabledUntil;
        const isSuperuserEnabled = superuserStatus !== undefined && superuserStatus.isAfter(Instant.now());
        return this.getQueryData([...key, makeUserQueryKey(user?.id, isSuperuserEnabled)], ...params);
    };

    setUserQueryData: QueryClient['setQueryData'] = (key, ...params) => {
        const user = this.store.getState().auth.user;
        const superuserStatus = this.store.getState().ui.superuserAccessEnabledUntil;
        const isSuperuserEnabled = superuserStatus !== undefined && superuserStatus.isAfter(Instant.now());
        return this.setQueryData([...key, makeUserQueryKey(user?.id, isSuperuserEnabled)], ...params);
    };

    fetchUserQuery: QueryClient['fetchQuery'] = (options, ...params) => {
        const user = this.store.getState().auth.user;
        const superuserStatus = this.store.getState().ui.superuserAccessEnabledUntil;
        const isSuperuserEnabled = superuserStatus !== undefined && superuserStatus.isAfter(Instant.now());
        return this.fetchQuery(
            {
                ...options,
                queryKey: [
                    ...options.queryKey,
                    makeUserQueryKey(user?.id, isSuperuserEnabled) as (typeof options.queryKey)[number],
                ] as any,
            },
            ...params,
        );
    };
}

export const asyncStoragePersister = createAsyncStoragePersister({
    storage: localforage,
});

export const persistOptions: PersistQueryClientProviderProps['persistOptions'] = {
    persister: asyncStoragePersister,
    dehydrateOptions: {
        shouldDehydrateQuery: (query) => {
            return query.options.meta?.persist ?? persistByDefault;
        },
    },
};
