/*
 * Some modules are loaded in the container so that they are available in module scope to the rest of the application.
 *
 * We guarantee that this setup file is imported after polyfills, but before the prelude and any other module.
 */
import { ConfigServiceImpl } from 'src/config/ConfigService';
import { ThinkAlphaQueryClientImpl } from 'src/config/query-client';
import { TypedContainer } from 'src/features/ioc/TypedContainer';
import { logsModule } from 'src/ioc/modules/logsModule';
import { webDatadogModule } from 'src/ioc/modules/webDatadogModule';
import type { StaticBindings } from 'src/ioc/types';

const container = new TypedContainer<StaticBindings>();
container.bind('QueryClient').to(ThinkAlphaQueryClientImpl).inSingletonScope();
container.bind('ConfigService').to(ConfigServiceImpl).inSingletonScope();
container.load(webDatadogModule);
container.load(logsModule);

// Ensure datadog RUM initializes
container.getOptional('DatadogService');

window.container = container;
