import type { MainBindings } from '../../../../native/main/ioc/types';
import { TypedContainer } from './TypedContainer';
import { injectable, type interfaces, inject as inversifyInject } from 'inversify';
import React, { useEffect, useMemo, useState } from 'react';
import { BehaviorSubject, from, skip } from 'rxjs';
import { type ReactContainer } from 'src/ioc/ReactContainer';
import type { ReactBindings } from 'src/ioc/types';
import { Logger } from 'src/ioc/types/Logger';

const iocContext = React.createContext<ReactContainer>(new TypedContainer<ReactBindings>());

export const useContainer = () => React.useContext(iocContext);

export const ContainerProvider = iocContext.Provider;

export const inject = <T extends keyof (ReactBindings & MainBindings)>(type: T) => {
    return inversifyInject(type as string);
};

@injectable()
export class ReactiveInjectable implements Disposable {
    protected disposableStack = new DisposableStack();

    #log!: Logger;

    @inject('Logger')
    private set log(log: Logger) {
        this.#log = log.getSubLogger({ name: 'ReactiveInjectable' });
    }

    __observable = new BehaviorSubject(this);

    constructor(...args: any[]) {
        if (Reflect.hasMetadata(REACTS_PARAMETERS_METADATA, this.constructor)) {
            for (const parameterIndex of Reflect.getMetadata(REACTS_PARAMETERS_METADATA, this.constructor)) {
                const value = args[parameterIndex];

                if (value === undefined) {
                    throw new Error('Must use `arguments` on `super()` when using @reacts');
                }

                // if (!(value instanceof ReactiveInjectable)) {
                // if (!('__observable' in value)) {
                //     throw new Error(
                //         'Cannot use `@reacts` with constructor parameter which is not a `ReactiveInjectable`.',
                //     );
                // }

                const subscription = from(value)
                    .pipe(skip(1))
                    .subscribe((_) => {
                        this.__observable.next(this);
                    });

                this.disposableStack.use({
                    [Symbol.dispose]: () => {
                        this.#log.debug({ message: 'disposing reactive parameter subscription' });
                        subscription.unsubscribe();
                    },
                });
            }
        }

        for (const arg of args) {
            if (Symbol.dispose in arg) {
                this.disposableStack.use(arg);
            }
        }
    }

    [Symbol.observable]() {
        return this.__observable;
    }

    /**
     * `Symbol.observable` is undefined in our runtime for some reason, so we use this as a fallback.
     */
    ['@@observable']() {
        return this.__observable;
    }

    rerender() {
        this.__observable.next(this);
    }

    [Symbol.dispose]() {
        this.#log.debug({ message: `disposing reactive injectable resources for ${this.constructor.name}` });
        this.disposableStack.dispose();
    }
}

const REACTS_PARAMETERS_METADATA = 'REACTS_PARAMETERS_METADATA';

export function reacts(target: any, propertyKey?: string, descriptorOrIndex?: number | PropertyDescriptor) {
    if (descriptorOrIndex === undefined) {
        throw new Error('Must use `@reacts` on either an accessor or constructor parameter.');
    }

    if (typeof descriptorOrIndex === 'number') {
        let reactsParameters: number[] = [];
        if (Reflect.hasOwnMetadata(REACTS_PARAMETERS_METADATA, target)) {
            reactsParameters = Reflect.getMetadata(REACTS_PARAMETERS_METADATA, target);
        }

        reactsParameters.push(descriptorOrIndex);

        Reflect.defineMetadata(REACTS_PARAMETERS_METADATA, reactsParameters, target);
    } else {
        const originalSet = descriptorOrIndex.set!;
        descriptorOrIndex.set = function (value) {
            originalSet.call(this, value);
            (this as any).__observable.next(this);
        };
    }
}

export const useReactive = <T extends ReactiveInjectable>(service: T): [T, number] => {
    const [v, setRender] = useState(0);

    useEffect(() => {
        const subscription = from(service)
            .pipe(skip(1))
            .subscribe(() => {
                setRender((i) => i + 1);
            });
        return () => subscription.unsubscribe();
    }, [service]);

    // Ensure value doesn't change unless the service does so this can be fed to context
    const value = useMemo<[T, number]>(() => [service, v], [service, v]);

    return value;
};

export const useReactiveSingleton = <T extends keyof ReactBindings>(type: T): [ReactBindings[T], number] => {
    const container = useContainer();

    if (
        (
            container.container as unknown as { _bindingDictionary: interfaces.Lookup<interfaces.Binding<unknown>> }
        )._bindingDictionary
            .get(type)
            .at(-1)?.scope !== 'Singleton'
    ) {
        throw new Error('`useReactiveSingleton` can only be used with constructors bound in "singleton" scope.');
    }

    const service = container.get(type);
    return useReactive(service as unknown as ReactiveInjectable) as [ReactBindings[T], number];
};

export { injectable };
