import { api } from '../api';
import { appConfig } from '../config';
import type { RawClient } from '@thinkalpha/table-client';
import { ConnectionStatus, ProxyClient } from '@thinkalpha/table-client';
import type { AxiosRequestConfig } from 'axios';
import { filter } from 'rxjs/operators';
import type {
    CreateTableByNlpPayload,
    CreateTablePayload,
    NlpTableCreationResult,
    TableCreationResult,
} from 'src/contracts/table';
import { container } from 'src/ioc/StaticContainer';

const client = new ProxyClient(appConfig.tableProxy);

const log = container.get('Logger').getSubLogger({ name: 'table-service' });

client.connectionStatus$.pipe(filter((x) => x === ConnectionStatus.badToken)).subscribe(() => {
    log.warn({ message: 'table-ws has rejected the user token' });
});

export const usePureProxyClient = () => {
    return client;
};

const exportedClient: RawClient = client;

export { exportedClient as client };

export const setAccessToken = (accessToken: (typeof client)['token']) => {
    log.info({ message: 'About to log in using token', accessToken });
    client.token = accessToken;
};

export const createTable = async (
    payload: CreateTablePayload,
    config?: AxiosRequestConfig,
): Promise<TableCreationResult> => {
    return (await api.post(`/tables`, payload, config)).data;
};

// ToDo: Standardize when we just send data and when not in the very near future
export const createTableFromNLP = async (
    payload: CreateTableByNlpPayload,
    config?: AxiosRequestConfig,
): Promise<NlpTableCreationResult> => {
    return (await api.post(`/tables/by-nlp`, payload, config)).data;
};
