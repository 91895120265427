import { internalReplacer, internalReviver } from './internal';
import localforage from 'localforage';

if (typeof window !== 'undefined') {
    window.localforage = localforage;
}

// double serialization hack here until we have the recursiveWalk function into platform-common
// although tbh, double serialization probably is faster because it happens in C++

const localforageWrapper: typeof localforage = Object.create(localforage);

localforageWrapper.setItem = function setItem(key, value, callback) {
    value = JSON.parse(JSON.stringify(value, internalReplacer));
    return localforage.setItem(key, value, callback);
};

localforageWrapper.getItem = function getItem(key, callback) {
    type T = NonNullable<Parameters<NonNullable<typeof callback>>[1]>;
    if (callback) {
        return localforage.getItem<T>(key, (err, value) => {
            if (err) return callback(err, value);
            value = JSON.parse(JSON.stringify(value), internalReviver);
            return callback(err, value);
        });
    } else {
        return localforage.getItem<T>(key).then((value) => {
            value = JSON.parse(JSON.stringify(value), internalReviver);
            return value;
        });
    }
};

export type LocalForage = typeof localforage;

export { localforageWrapper as localforage };
