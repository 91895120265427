import type { StaticBindings } from '../types';
import type { DatadogService } from '../types/DatadogService';
import type { Logger as LoggerType, LogData } from '../types/Logger';
import type { StatusType } from '@datadog/browser-logs';
import debugFactory from 'debug';
import isEmpty from 'lodash/isEmpty';
import { TypedContainerModule, type TypedContainer } from 'src/features/ioc/TypedContainer';
import { type ILogObjMeta, BaseLogger, type ISettingsParam } from 'tslog';

export class Logger extends BaseLogger<LogData> implements LoggerType {
    silly(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(0, 'SILLY', msg, context);
        }
        return super.log(0, 'SILLY', msg);
    }
    trace(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(1, 'TRACE', msg, context);
        }
        return super.log(1, 'TRACE', msg);
    }
    debug(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(2, 'DEBUG', msg, context);
        }
        return super.log(2, 'DEBUG', msg);
    }
    info(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(3, 'INFO', msg, context);
        }
        return super.log(3, 'INFO', msg);
    }
    warn(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(4, 'WARN', msg, context);
        }
        return super.log(4, 'WARN', msg);
    }
    error(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(5, 'ERROR', msg, context);
        }
        return super.log(5, 'ERROR', msg);
    }
    fatal(msg: string | LogData, context?: Record<string, unknown>) {
        if (typeof msg === 'string') {
            return super.log(6, 'FATAL', msg, context);
        }
        return super.log(6, 'FATAL', msg);
    }
    getSubLogger(settings?: ISettingsParam<LogData>, logObj?: LogData): Logger {
        return super.getSubLogger(settings, logObj) as Logger;
    }
}

function debugTransport() {
    const debuggers = new Map<string, ReturnType<typeof debugFactory>>();

    debugFactory.enable('*,-socket.io*,-engine.io*,-micromark*,-ta:feat:FilterEditor:*,-ta:feat:CodeEditor:*');

    const transport = (msg: LogData & ILogObjMeta) => {
        let name = msg._meta.name;
        if (!name) {
            name = 'log';
        }

        if (!debuggers.has(name)) {
            debuggers.set(name, debugFactory(name));
        }

        const { message, _meta: _discarded, ...context } = msg;

        const formatStr = `[${msg._meta.logLevelName}] ${message}`;

        if (isEmpty(context)) {
            debuggers.get(name)?.(formatStr);
        } else {
            debuggers.get(name)?.(`${formatStr} %o`, context);
        }
    };

    return transport;
}

function datadogTransport(datadogLogger: DatadogService['logger']) {
    const ERROR = 5;
    const WARN = 4;
    const INFO = 3;
    const DEBUG = 2;

    const levelToName: Record<number, StatusType> = {
        [ERROR]: 'error',
        [WARN]: 'warn',
        [INFO]: 'info',
        [DEBUG]: 'debug',
    };

    return (logObj: LogData & ILogObjMeta) => {
        const { message, _meta: _discarded, ...context } = logObj;

        if ([ERROR, WARN, INFO, DEBUG].includes(logObj._meta.logLevelId)) {
            datadogLogger.log(message, context, levelToName[logObj._meta.logLevelId]);
        }
    };
}

export const logsModule = new TypedContainerModule<StaticBindings>((bind) => {
    bind('Logger').toDynamicValue((ctx) => {
        const container = ctx.container as unknown as TypedContainer<StaticBindings>;

        const transports = [debugTransport()];

        if (container.isBound('DatadogService')) {
            const datadogLogger = container.get('DatadogService').logger;
            transports.push(datadogTransport(datadogLogger));
        }

        return new Logger({
            // Do not ship logs to console using the built-in pretty-printer or JSON format
            type: 'hidden',
            attachedTransports: transports,
        });
    });
});
