import i18next, { t } from 'i18next';
import Locize, { type LocizeBackendOptions } from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import { appConfig } from 'src/config';

i18next
    .use(Locize)
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init<LocizeBackendOptions>({
        backend: {
            projectId: 'e51bcb59-5061-43ae-ac1e-5b39de0b3936',
            referenceLng: 'en',
            version: appConfig.i18nVersion,
        },
        defaultNS: 'translation',
        fallbackLng: 'en',
        keySeparator: false,
        nsSeparator: false,
    });

export { t };
